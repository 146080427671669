import moment from 'moment';

function load_blocked_dates() {
  return new Promise(function(resolve, reject) {
    $.ajax({
      url: '/viz_techs/blocked_dates',
      data: {
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().add(2, 'months').format('YYYY-MM-DD'),
        token: $('#token').val() },
      success: function(response) {
        resolve(response)
      },
      error: function(err) {
        reject(err)
      }
    });
  });
}

document.addEventListener('DOMContentLoaded', function() {
  var events = [];
  var calendarEl = $('#calendar-viz-tech');
  var existingAppointments = calendarEl.data('events');
  var blockedDates = calendarEl.data('blocked');

  // Add existing blocked dates to the calendar
  if (blockedDates) {
    blockedDates.forEach(function(blockedDate) {
      events.push({
        start: moment(blockedDate).format('YYYY-MM-DD'),
        end: moment(blockedDate).add(1, 'days').format('YYYY-MM-DD'),
        rendering: 'background',
        color: '#ff9f89',
        overlap: false
      })
    });
  }

  // Add existing appointments to the calendar
  if (existingAppointments) {
    existingAppointments.forEach(function(appointment) {
      events.push({
        title: 'Scheduled Appointment',
        start: appointment.scheduled_to,  // Assuming `scheduled_to` is already a datetime string
        allDay: false
      });
    });
  }

  var calendar = calendarEl.fullCalendar({
    events: events,
    validRange: function(nowDate) {
      return {
        start: nowDate.subtract(1, 'days'),
        end: nowDate.clone().add(2, 'months')
      };
    },
    dayClick: function(date, jsEvent, view) {
      var selectedDate = moment(date).format('YYYY-MM-DD');
      var today = moment().format('YYYY-MM-DD');

      // Prevent selecting past dates
      if (selectedDate < today) {
        return;
      }

      if (existingAppointments && existingAppointments.length > 0) {
        return;
      }

      // Prevent selecting dates that are already in the events array (including the background ones)
      var dateConflict = events.some(function(event) {
        return moment(event.start).format('YYYY-MM-DD') === selectedDate;
      });

      if (dateConflict) {
        return;
      }

      var token = $('#token').val();

      $(this).addClass('focused-day')

      $('#selected-date-modal').text(selectedDate);
      $('#date').val(selectedDate);

      // Fetch available times for the selected date
      $.ajax({
        url: '/viz_techs/available_times',
        data: { date: selectedDate, token: token },
        success: function(response) {
          var timesDropdown = $('#appointment_time');
          timesDropdown.empty();
          response.times.forEach(function(time) {
            timesDropdown.append($('<option>').val(time.value).text(time.text));
          });

          // Show the modal
          $('#appointmentModal').modal('show');
        },
        error: function() {
          alert('Error loading available times.');
        }
      });
    }
  });


  $('#appointmentModal').on('hide', function () {
    $('.fc-day').removeClass('focused-day')
  })
});
