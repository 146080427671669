import React from 'react';
import Loading from '../common/Loading';
import Fact from '../common/Fact';
import Note from '../common/Note';
//import TextInput from '../common/TextInput';
//import CustomerBrief from './Brief';
import {Link} from 'react-router-dom'

class ServiceOrderBrief extends React.Component
{
  constructor(props) {
    super(props);
    this.renderIssue = this.renderIssue.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  formatIntent(intent)
  {
    if(intent && intent.length > 2)
    {
      return(
          <div className="label label-info">{intent}</div>
      )
    }
    else
    {
      return(null)
    }
  }

  listItemFor(issue)
  {
    let resolution_class = "fa fa-chevron-right"
    if( issue.resolved == false ) { resolution_class = "fa fa-times" }
    else if( issue.resolved == true ) {  resolution_class = "fa fa-check" }

    return(
      <span className="fa-li"><i className={resolution_class}></i></span>
    )
  }
  renderIssue(issue)
  {
    return(
        <li className="item-issue" key={issue.id}>
          {this.listItemFor(issue)}
          {this.formatIntent(issue.intent)} {issue.description}
        </li>
        )
  }

  renderItem(item)
  {
    return(
      <div key={item.id}>
        <b>{item.description}:</b>
        <ul className="fa-ul">{item.issues.map(this.renderIssue)}</ul>
      </div>
    )
  }

  renderFollowUp(service)
  {
    if(service.class == 'ServiceOrder')
    {
      return <button className="btn btn-success" onClick={this.create_followup(service)}>Create Follow-Up Service Order</button>
    }
    else
    {
      return(null);
    }
  }

  create_followup(service)
  {
    return( () => {document.location.href = "/ng/#/service_orders/" + service.id + "/follow-up"});
  }

  render()
  {
    return(
      <div className="app-Service well well-small" key={this.props.service_order.id}>
        <div className="label">{this.props.service_order.status}</div>
        <h3 className="ident">
          <a href={this.props.service_order.path}>{this.props.service_order.ident}</a>
          : {this.props.service_order.client.name} {this.props.service_order.number}
        </h3>
        {this.props.service_order.items && this.props.service_order.items.map(this.renderItem)}

        <div>Entered: {this.props.service_order.entered}</div>

        {this.renderFollowUp(this.props.service_order)}
      </div>
    );
  }
}

export default class CustomerShow extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.customerLoad(this.props.match.params.customer_id);
    this.props.servicesLoad(this.props.match.params.customer_id);
    this.props.notesLoad(this.props.match.params.customer_id);
  }

  render()
  {
    if( !this.props.customer )
    {
      return(
        <Loading />
      );
    }
    else
    {
      return(this.actuallyRender());
    }
  }

  renderActionButton(label, fn, actuallyRender)
  {
    if(actuallyRender)
    {
      return(
        <button className="btn btn-default" onClick={fn}>{label}</button>
      )
    }
    else
    {
      return(null);
    }
  }

  renderService(service_order)
  {
    return(
        <ServiceOrderBrief service_order={service_order} key={service_order.id}/>
    )
  }

  actuallyRender()
  {
    return(
      <div>
        {this.renderHeader(this.props.customer)}
        <div className="row">
          <div className="span3">
            {this.renderFacts(this.props.facts.address)}
            {this.renderUntrackableAddressAlert()}
            {this.renderAmbiguousAddressAlert()}
            {this.renderMappingAddress(this.props.facts.mapping_address)}
            {this.renderFacts(this.props.facts.phone)}
            {this.renderFacts(this.props.facts.email)}
          </div>
          <div className="span5">
            <h2>Service History</h2>
            {this.props.services.map(this.renderService)}
          </div>

          <div className="span4">
            <h2>Customer Notes</h2>
            {this.props.notes && this.renderNotes(this.props.notes) }
          </div>
        </div>
        <div className="row">
          <div className="span3">
          </div>
          <div className="span8">
            {this.renderActionButton('New Service Order', this.new_service_order(this.props.customer), this.props.feature_flags.includes('claims'))}
            {this.renderActionButton('New PreQualification', this.new_preq(this.props.customer), this.props.feature_flags.includes('preqs'))}
            {this.renderActionButton('New Call', this.new_call(this.props.customer), this.props.feature_flags.includes('customer_service'))}
            {this.renderActionButton('New VizTech', this.new_viz_tech(this.props.customer), this.props.feature_flags.includes('viz_techs'))}
          </div>
        </div>
      </div>
    )
  }

  renderNotes(notes)
  {
    return(
      notes.map( note =>  <Note note={note} key={note.id} />)
    );
  }

  renderAmbiguousAddressAlert()
  {
    if (this.props.facts.address[0].ambiguous && this.props.facts.mapping_address.length == 0) {
      return(
        <div className="alert alert-error">
          <p><strong>Ambiguous Address</strong></p>
          <p>This location can't be tracked on the map. Please <a href={"/customers/" + this.props.customer.id + "/edit"}>fix it or Add a Mapping Address</a>, so the Technician can meet the customer.</p>
        </div>
      )
    }
  }

  renderUntrackableAddressAlert()
  {
    if (this.props.facts.address[0] && this.props.facts.address[0].status == 'untrackable' && this.props.facts.mapping_address.length == 0) {
      return(
        <div className="alert alert-error">
          <p><strong>Untrackable Address</strong></p>
          <p>This location can't be tracked on the map. Please <a href={"/customers/" + this.props.customer.id + "/edit"}>fix it or Add a Mapping Address</a>, so the Technician can meet the customer.</p>
        </div>
      )
    }
  }

  renderMappingAddress(facts)
  {
    if(facts && facts[0] && facts[0].value) {
      return(
        this.renderFacts(facts)
      );
    }
  }

  renderFacts(facts)
  {
    return(
      facts.map( (fact) => { return(<Fact fact={fact} key={fact.name + fact.value} />)})
    );
  }

  new_service_order(customer)
  {

    return( () => {document.location.href = "/ng/#/customers/" + customer.id + "/service_orders/new"});
  }

  new_preq(customer)
  {
    return( () => {document.location.href = "/ng/#/customers/" + customer.id + "/pre_qs/new"});
  }

  new_viz_tech(customer)
  {
    return( () => {document.location.href = "/ng/#/customers/" + customer.id + "/viz_techs/new"});
  }

  new_call(customer)
  {
    return( () => {document.location.href = "/calls/new?call[customer_id]=" + customer.id});
  }

  renderHeader(customer)
  {
    return(
      <div className="page-header">
        <h1><span className="muted">C{customer.id}</span> {customer.first_name} {customer.last_name}</h1>
        <Link to={'/customers/' + customer.id + '/edit'} className="btn">Edit</Link>
      </div>
    )
  }
}
