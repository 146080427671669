app.controller('NewVizTechCtrl', [
  '$scope', '$routeParams', '$location', 'fixRailsSerializer', 'Customer', 'Client', 'VizTech',
  function($scope, $routeParams, $location, fixRailsSerializer, Customer, Client, VizTech ) {
    $scope.store_id = document.current_user_store_id;
    $scope.store_rep = $scope.store_id != undefined;

    Client.query().then(function(res){
      $scope.clients = res
      if($scope.store_rep)
      {
        $scope.viztech.client = $scope.clients[0];
      }
    });

    $scope.viztech = {customer_id: $routeParams['customer_id'], call_time_estimate: 900};

    $scope.save_viztech = function()
    {
      function visit_viztech_items_page(viztech)
      {
        $location.path('/viz_techs/' + viztech.id + '/items');
      }

      var viztech = new VizTech($scope.viztech);
      viztech.client_id = viztech.client.id
      delete viztech.client
      viztech.create().then( visit_viztech_items_page )
    }

    $scope.selectable_times = [{"description":"15 minutes","seconds":900}];
  }
]);
