function scrollChatToLastMessage () {
  var chatBody = document.getElementsByClassName('chat-body')[0];
  if (chatBody) {
    chatBody.scrollTop = chatBody.scrollHeight;
  }

  var messages = $('.chat-body .chat-message')

  if (messages && messages.length > 0) {
    messages.addClass('chat-actions-disabled');

    if ($('.chat-for-customer').length > 0) {
      $(messages[messages.length - 1]).removeClass('chat-actions-disabled');
    }
  }
}

function toggleChatSendMessageButton(event) {
  let val = event.target.value;

  if (val.length > 0) {
    $('.chat-send-button').removeAttr('disabled')
  } else {
    $('.chat-send-button').attr('disabled', 'disabled')
  }
}

$(document).on('click', '.chat-action', function (e) {
  if ($(this).closest('.chat-actions-disabled').length > 0) {
    e.preventDefault();
    e.stopPropagation();
  }
})

$(document).on('DOMSubtreeModified', '.chat-body', function (e) {
  scrollChatToLastMessage();
})

// Listening to paste events
$(document).on('input propertychange', '#visitation_link_message_content', function (e) {
  toggleChatSendMessageButton(e);
})

$(document).on('change', '#visitation_link_message_content', function (e) {
  toggleChatSendMessageButton(e);
})

$(document).on('keyup', '#visitation_link_message_content', function (e) {
  toggleChatSendMessageButton(e);
})

$(document).on('turbo:submit-start', 'form.new_visitation_link_message', function (e) {
  $('.chat-send-button', e.target).attr('disabled', 'disabled');
  $('#visitation_link_message_content', e.target).attr('disabled', 'disabled');
})

$(document).on('turbo:submit-end', 'form.new_visitation_link_message', function (e) {
  $('#visitation_link_message_content', e.target).val('');
  $('#visitation_link_message_content', e.target).removeAttr('disabled');
  $('#visitation_link_message_content').trigger('change');
})

$(document).ready(function () {
  scrollChatToLastMessage();
})
