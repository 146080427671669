import { tiimepicker } from '../../pck';

app.controller('ScheduleProfileCtrl', [
  '$scope', '$attrs', '$routeParams', 'fixRailsSerializer', 'Client', 'Employee', 'Assignment',
  function($scope, $attrs, $routeParams, fixRailsSerializer, Client, Employee, Assignment) {
    Employee.get($attrs.employeeId).then(function(employee) {
      $scope.employee = employee;
      if ($scope.employee.schedule_profile.attributes.excluded_clients == null) {
        $scope.employee.schedule_profile.attributes.excluded_clients = [];
      };
    }).then(function() {
      const schedule = $scope.employee.schedule_profile.attributes.schedule || {};

      $scope.default_activity_id = $scope.employee.default_activity_id;
      $scope.businessHours = $("#businessHoursContainer").businessHours({
        operationTime: Object.keys(schedule),
        firstDayOfTheWeek: 1,
        postInit: function() {
          for (var i = 0; i < 7; i++) {
            let dbTime = schedule[i]
            let time = dbTime && dbTime.startTime || '07:00'
            let maxTime = dbTime && dbTime.maxTime || '10'
            let date = new Date()

            date.setHours(...time.split(':'))

            var args = {
              format: false,
              meridiem: false,
              start: date
            }

            new tiimepicker($(`#businessHoursContainer input.timepicker[day-value='${i}']`)[0], args)

            $(`#businessHoursContainer select[day-value='${i}']`).val(maxTime)
          }
        },
        dayTmpl:   '   <div class="dayContainer">  '  +
               '     <div data-original-title="" class="colorBox">  '  +
               '       <input type="checkbox" class="invisible operationState">  '  +
               '     </div>  '  +
               '     <div class="weekday"></div>  '  +
               '     <div class="time__input">    ' +
  	            '       <input type="text" class="timepicker" />' +
                '   </div>' +
                '<select name="max_time">'+
                '  <option value="1">1 Hours</option>'+
                '  <option value="2">2 Hours</option>'+
                '  <option value="3">3 Hours</option>'+
                '  <option value="4">4 Hours</option>'+
                '  <option value="5">5 Hours</option>'+
                '  <option value="6">6 Hours</option>'+
                '  <option value="7">7 Hours</option>'+
                '  <option value="8">8 Hours</option>'+
                '  <option value="9">9 Hours</option>'+
                '  <option value="10">10 Hours</option>'+
                '  <option value="11">11 Hours</option>'+
                '  <option value="12">12 Hours</option>'+
                '  <option value="13">13 Hours</option>'+
                '  <option value="14">14 Hours</option>'+
                '  <option value="15">15 Hours</option>'+
                '  <option value="16">16 Hours</option>'+
                '  <option value="17">17 Hours</option>'+
                '  <option value="18">18 Hours</option>'+
                '  <option value="19">19 Hours</option>'+
                '  <option value="20">20 Hours</option>'+
                '  <option value="21">21 Hours</option>'+
                '  <option value="22">22 Hours</option>'+
                '  <option value="23">23 Hours</option>'+
                '  <option value="24">24 Hours</option>'+
                '</select>' +
                '  </div>  '
      });
      if ($("#businessHoursContainer2ndShift").length > 0) {
        $scope.businessHours2ndShift = $("#businessHoursContainer2ndShift").businessHours({
          operationTime: Object.keys(schedule),
          firstDayOfTheWeek: 1,
          postInit: function() {
            for (var i = 0; i < 7; i++) {
              let dbTime = schedule[`${i}-2`]
              let time = dbTime && dbTime.startTime || '12:00'
              let maxTime = dbTime && dbTime.maxTime || '4'
              let date = new Date()

              date.setHours(...time.split(':'))

              var args = {
                format: false,
                meridiem: false,
                start: date
              }

              new tiimepicker($(`#businessHoursContainer2ndShift input.timepicker[day-value='${i}']`)[0], args)

              $(`#businessHoursContainer2ndShift select[day-value='${i}']`).val(maxTime)
            }
          },
          dayTmpl:   '   <div class="dayContainer">  '  +
                 '     <div data-original-title="" class="colorBox">  '  +
                 '       <input type="checkbox" class="invisible operationState">  '  +
                 '     </div>  '  +
                 '     <div class="weekday"></div>  '  +
                 '     <div class="time__input">    ' +
                  '       <input type="text" class="timepicker" />' +
                  '   </div>' +
                  '<select name="max_time">'+
                  '  <option value="1">1 Hours</option>'+
                  '  <option value="2">2 Hours</option>'+
                  '  <option value="3">3 Hours</option>'+
                  '  <option value="4">4 Hours</option>'+
                  '  <option value="5">5 Hours</option>'+
                  '  <option value="6">6 Hours</option>'+
                  '  <option value="7">7 Hours</option>'+
                  '  <option value="8">8 Hours</option>'+
                  '  <option value="9">9 Hours</option>'+
                  '  <option value="10">10 Hours</option>'+
                  '  <option value="11">11 Hours</option>'+
                  '  <option value="12">12 Hours</option>'+
                  '  <option value="13">13 Hours</option>'+
                  '  <option value="14">14 Hours</option>'+
                  '  <option value="15">15 Hours</option>'+
                  '  <option value="16">16 Hours</option>'+
                  '  <option value="17">17 Hours</option>'+
                  '  <option value="18">18 Hours</option>'+
                  '  <option value="19">19 Hours</option>'+
                  '  <option value="20">20 Hours</option>'+
                  '  <option value="21">21 Hours</option>'+
                  '  <option value="22">22 Hours</option>'+
                  '  <option value="23">23 Hours</option>'+
                  '  <option value="24">24 Hours</option>'+
                  '</select>' +
                  '  </div>  '
        });
      }
    }).then(function() {
      _.each(['leather', 'cleaning'], function(skill) {
        if ($scope.employee.schedule_profile.attributes[`skill_${skill}`]) {
          $(`#skill-${skill}-cb`).prop('checked', true).checkboxradio('refresh')
        }
      });
    });

    Client.get().then(function(clients) {
      $scope.clients = _.map(clients, function(client) {
        return {client_id: client.id, client_name: client.name};
      });
    });

    $scope.update_schedule = function(event) {
      event.preventDefault();

      if ($('.save-schedule-btn').attr('disabled') == 'disabled') {
        return;
      }

      $('.save-schedule-btn').attr('disabled', 'disabled');
      if ($scope.businessHours2ndShift) {
        $scope.employee.schedule_profile.attributes.schedule = {...$scope.businessHours.serialize(), ...$scope.businessHours2ndShift.serialize(2)};
      } else {
        $scope.employee.schedule_profile.attributes.schedule = {...$scope.businessHours.serialize()};
      }
      delete $scope.employee.schedule_profile.attributes.work_days;

      if ($scope.employee.default_activity_id == null) {
        alert('Please select a Default Activity');
        $('.save-schedule-btn').removeAttr('disabled');
      } else {
        $scope.employee.update().then(function () {
          $('#api-success').show();
          $('.save-schedule-btn').removeAttr('disabled');
        }, function () {
          $('.save-schedule-btn').removeAttr('disabled');
        });
      }
    };

    $scope.exclude_me = { };
    $scope.lock_scheduling_conditions = true;

    $scope.remove_exclusion = function(client, event) {
      event.preventDefault();

      var idx = $.inArray(client, $scope.employee.schedule_profile.attributes.excluded_clients);
      if (idx > -1) {
        $scope.employee.schedule_profile.attributes.excluded_clients.splice(idx, 1);
      }
      $scope.employee.save();
    };

    $scope.remove_assignment = function(assignment, event) {
      event.preventDefault();

      var assignment_obj = new Assignment(assignment);
      assignment_obj.delete().then(function () {
        var i = $scope.employee.assignments.indexOf(assignment);
        $scope.employee.assignments.splice(i, 1);
      });
    };

    $scope.toggle_skill = function(skill) {
      this.toggle_attr(`skill_${skill}`);
    };

    $scope.toggle_attr = function(attr) {
      const attrs = $scope.employee.schedule_profile.attributes;
      const prev = attrs[attr];
      attrs[attr] = !attrs[attr];
    }

    $scope.selectable_times = [{"description":"20 minutes","seconds":1200},{"description":"25 minutes","seconds":1500},{"description":"30 minutes","seconds":1800},{"description":"35 minutes","seconds":2100},{"description":"40 minutes","seconds":2400},{"description":"45 minutes","seconds":2700},{"description":"1 hour","seconds":3600},{"description":"1 hour, 30 minutes","seconds":5400},{"description":"2 hours","seconds":7200},{"description":"2 hours, 30 minutes","seconds":9000},{"description":"3 hours","seconds":10800},{"description":"3 hours, 30 minutes","seconds":12600},{"description":"4 hours","seconds":14400},{"description":"4 hours, 30 minutes","seconds":16200},{"description":"5 hours","seconds":18000},{"description":"5 hours, 30 minutes","seconds":19800},{"description":"6 hours","seconds":21600},{"description":"6 hours, 30 minutes","seconds":23400},{"description":"7 hours","seconds":25200},{"description":"7 hours, 30 minutes","seconds":27000},{"description":"8 hours","seconds":28800},{"description":"8 hours, 30 minutes","seconds":30600},{"description":"9 hours","seconds":32400},{"description":"9 hours, 30 minutes","seconds":34200},{"description":"10 hours","seconds":36000},{"description":"10 hours, 30 minutes","seconds":37800}];

    $scope.toggle_activity = function(activity_id) {
      $scope.employee.default_activity_id = activity_id;
    };

    $scope.exclude_client = function(event) {
      event.preventDefault();

      var exclude_me = {
        client_name: $scope.exclude_me.client_name,
        client_id: $scope.exclude_me.client_id
      };

      if ($scope.can_exclude(exclude_me.client_id)) {
        if ($scope.employee.schedule_profile.attributes.excluded_clients == null) {
          $scope.employee.schedule_profile.attributes.excluded_clients = [];
        };
        $scope.employee.schedule_profile.attributes.excluded_clients.push(exclude_me);
        $scope.employee.save();
      }
    };

    $scope.add_assignment = function(assignment, event) {
      event.preventDefault();

      var assignment_obj = new Assignment($scope.assignment);
      assignment_obj.employee_id = $attrs.employeeId
      assignment_obj.activity_type = "Activity"
      assignment_obj.automatic = false
      assignment_obj.create().then( function(response) {
        $scope.employee.assignments.push(assignment_obj)
      });
    };

    $scope.can_exclude = function(client_id) {
      if (client_id) {
        const already_excluded = _.map($scope.employee.schedule_profile.attributes.excluded_clients, 'client_id');
        return !_.includes(already_excluded, client_id);
      } else {
        return false;
      }
    }
  }
]);
