// The Datepicker:
// https://www.eyecon.ro/bootstrap-datepicker/

// https://www.grobmeier.de/angular-js-binding-to-jquery-ui-datepicker-example-07092012.html#.UZQvt7WbO30

// https://eternicode.github.io/bootstrap-datepicker
//
// The directive:
// https://www.grobmeier.de/angular-js-binding-to-jquery-ui-datepicker-example-07092012.html#.UZQvt7WbO30
// other possible directives:
//     https://gist.github.com/HasAndries/3135128
//
angular.module('datepickr', [])
  .config([function() {
    // Configuration code (empty for now)
  }])
  .directive('datepicker', ['$parse', function($parse) {
    return function(scope, element, attrs) {
      function putObject(path, object, value) {
        var modelPath = path.split(".");

        function fill(object, elements, depth, value) {
          var hasNext = ((depth + 1) < elements.length);
          if (depth < elements.length && hasNext) {
            if (!object.hasOwnProperty(modelPath[depth])) {
              object[modelPath[depth]] = {};
            }
            fill(object[modelPath[depth]], elements, ++depth, value);
          } else {
            object[modelPath[depth]] = value;
          }
        }
        fill(object, modelPath, 0, value);
      }

      const datepickerOptions = {
        format: 'mm/dd/yyyy',
        altFormat: 'yyyy-mm-dd'
      };

      if (element.data('datepicker-week-start')) {
        datepickerOptions.weekStart = element.data('datepicker-week-start');
      }

      if (element.data('datepicker-date-format')) {
        datepickerOptions.format = element.data('datepicker-date-format');
      }

      element.datepicker(datepickerOptions)
        .on('changeDate', function(ev) {
          const date = ev.currentTarget.value;
          const ngModel = $parse(attrs.ngModel);

          const setValue = function() {
            scope.$apply(function(scope) {
              ngModel.assign(scope, date);
            });
          };

          const triggerNgChange = function() {
            $parse(attrs.ngChange)(scope);
          };

          setValue();
          triggerNgChange();

          if (attrs.closeOnDateSelection === 'true') {
            element.datepicker('hide');
          }
        });
    }
  }]);
