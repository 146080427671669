window.MapQuestHelper = class {
  constructor(id) {
    L.mapquest.key = document.getElementById('mapquest-key').value;

    this.mapID = id
    this.map = null
  }

  initializeMap () {
    window.map = this.map = L.mapquest.map(this.mapID, {
      center: [37.2756, -104.6559],
      layers: L.mapquest.tileLayer('map'),
      zoom: 4
    });
  };

  reset () {
    let self = this;

    return new Promise(function (resolve, reject) {
      if (self.map) {
        self.map.remove();
        window.Rainbow.reset();

        self.initializeMap(self.mapID);
      } else {
        self.initializeMap(self.mapID);

      }
      resolve();
    })
  };

  addMarker (latitude, longitude) {
    let coordinates = [latitude, longitude];
    let icon = L.mapquest.icons.marker();
    let marker = L.marker(coordinates, { icon: icon, draggable: false, alt: false })

    marker.addTo(this.map);
    this.setCenter(coordinates, 15);
  };

  setCenter (coordinates, zoom) {
    this.map.setZoom(zoom);
    this.map.setView(coordinates);
  }

  addServiceOrders  (serviceOrders, options) {
    let self = this;

    return serviceOrders.map(function(serviceOrder) {
      let coordinates = null;

      if (serviceOrder.customer && serviceOrder.mapping_address && serviceOrder.mapping_address.coordinates && serviceOrder.mapping_address.coordinates[0] && serviceOrder.mapping_address.coordinates[1]) {
        coordinates = serviceOrder.mapping_address.coordinates;
      } else if (serviceOrder.customer && serviceOrder.customer.mapping_address && serviceOrder.customer.mapping_address.location && serviceOrder.customer.mapping_address.location.coordinates && serviceOrder.customer.mapping_address.location.coordinates[0] && serviceOrder.customer.mapping_address.location.coordinates[1]) {
        coordinates = serviceOrder.customer.mapping_address.location.coordinates;
      } else if (serviceOrder.mapping_address && serviceOrder.mapping_address.coordinates && serviceOrder.mapping_address.coordinates[0] && serviceOrder.mapping_address.coordinates[1]) {
        coordinates = serviceOrder.mapping_address.coordinates;
      } else {
        coordinates = serviceOrder.coordinates;
      }

      if (coordinates[0] && coordinates[1]) {
        let layer = L.layerGroup();
        let originalIcon;
        let altIcon;
        let marker;

        if (serviceOrder && serviceOrder.marker) {
          let url = '/pin-black-' + serviceOrder.marker + '.png'
          if (parseInt(serviceOrder.marker) > 50) {
            url = '/pin-black.png'
          }

          originalIcon = L.icon({
            iconUrl: url,
            iconSize: [30, 45]
          })

          altIcon = L.icon({
            iconUrl: url,
            iconSize: [30, 45]
          })

          marker = L.marker(coordinates, { icon: originalIcon, draggable: true, alt: false })
        } else {
          originalIcon = L.icon({
            iconUrl: '/pin-purple.png',
            iconSize: [15, 25]
          })

          altIcon = L.icon({
            iconUrl: '/pin-yellow.png',
            iconSize: [15, 25]
          })

          marker = L.marker(coordinates, { icon: originalIcon, draggable: true, alt: false })
        }

        if (options.showTooltip) {
          let description = "<nobr>S" + serviceOrder.id + " - " + serviceOrder.zone_name + "</nobr><br/><br/>" +
                            "<nobr>Client: " + serviceOrder.client_name + "</nobr><br/>" +
                            "<nobr>Work Time: " + serviceOrder.work_time + "</nobr>";
          marker.bindTooltip(description);
        }

        if (options.clickable) {
          marker.addEventListener('click', function(event) {
            event.target.options.alt = !event.target.options.alt;

            if (event.target.options.alt) {
              event.target.setIcon(altIcon);
            } else {
              event.target.setIcon(originalIcon);
            }

            if (options.onClick) {
              options.onClick(event, serviceOrder);
            }
          })
        }

        marker.addTo(layer);
        layer.addTo(self.map);
        self.setCenter(coordinates, 9);
        return layer;
      } else {
        console.log("Skipping ServiceOrder " + serviceOrder.id + " because it has no coordinates");
        return null;
      }
    })
  }

  async fetchStaticMapUrls(zipCodes) {
    return new Promise((resolve, reject) => {
      const zipCodesParam = zipCodes.join(',');
      fetch(`/zipcodes/polylines?zip_codes=${zipCodesParam}`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => resolve(data))
        .catch(error => {
          console.error('Failed to fetch static map encode:', error);
          reject(error);
        });
    });
  }

  outlineRoutes(routes, useMarkers, options) {
    return this.buildZipCodeLayers(routes).then(() => {
      let promises = routes.map(route => {
        return this.outlineRoute(route, useMarkers, options);
      });
      return Promise.all(promises);
    });
  }

  buildZipCodeLayers(routes) {
    var self = this;
    return new Promise(function (resolve, reject) {
      let routePromises = routes.map(route => {
        route.color = route.color || window.Rainbow.get_color();

        const zipcodes = route.serviced_zipcodes;
        return self.fetchStaticMapUrls(zipcodes)
          .then(encoded => {
            const points = encoded['zipcodes'];
            const layerGroup = L.layerGroup();
            route.zipOutline = layerGroup;
            let zipPromises = points.map(item => self.addZipCodePolygon(item, route));
            return Promise.all(zipPromises);
          })
          .catch(error => {
            console.error('Error fetching or outlining zip codes:', error);
            return Promise.reject(error);
          });
      });

      Promise.all(routePromises)
          .then(() => resolve())
          .catch(reject);
    });
  }

  addZipCodePolygon(item, route) {
    return new Promise(function (resolve, reject) {
      var poly = L.polygon(item['coordinates'], {
          weight: 1,
          color: '#000000',
          fillColor: '#1bffee',
          fillOpacity: 0.2,
          opacity: 0.4
      });

      route.zipOutline.addLayer(poly);
      resolve();
    });
  }

  outlineZipCodes(route) {
    route.zipOutline.addTo(this.map);
  }

  outlineRoute (route, useMarkers, options) {
    let self = this;

    return new Promise(function(resolve, reject) {
      route.outline = L.layerGroup();

      var routePolygon = L.polygon(self.flattenWaypoints(route.waypoints), {
        weight: 1.5,
        color: route.color,
        fillColor: route.color,
        fillOpacity: 0.3,
        opacity: 0.5
      });

      var originCircle = L.circle([route.origin.latitude, route.origin.longitude], 3000, {
        weight: 1,
        color: route.color,
        fillColor: route.color,
        fillOpacity: 0.3,
        opacity: 0.5
      });

      route.outline.addLayer(routePolygon);
      route.outline.addLayer(originCircle);

      if (useMarkers) {
        let waypoints = route.waypoints.filter(wpt => wpt.latitude);
        angular.forEach(waypoints, function( wpt ) {
          var icon = L.icon({
            iconUrl: wpt.icon_path,
            shadowUrl: undefined,
            iconSize: [28, 28]
          });

          let description = "<nobr>" + wpt.description + "</nobr><br/><nobr>Work Time: " + wpt.work_duration;

          wpt.marker = L.marker([wpt.latitude, wpt.longitude], { icon: icon, title: wpt.icon, draggable: true });
          wpt.marker.bindTooltip(description);
          wpt.marker.bindPopup(description);

          wpt.marker.addTo(route.outline);
        });
      }

      if (options.onClick) {
        routePolygon.addEventListener('click', function(event) {
          options.onClick(event, route);
        })

        originCircle.addEventListener('click', function(event) {
          options.onClick(event, route);
        })

        route.outline.addEventListener('click', function(event) {
          options.onClick(event, route);
        })
      }

      route.outline.addTo(self.map);

      self.setCenter([parseFloat(route.center[0]), parseFloat(route.center[1])], 9);

      resolve();
    })
  };

  flattenWaypoints ( waypoints )
  {
    waypoints = waypoints.filter(wpt => wpt.latitude);
    let out = waypoints.map(function(wpt) {
      return([parseFloat(wpt.latitude), parseFloat(wpt.longitude)])
    });

    return out;
  }

  remove (item) {
    if(item === undefined || item === null) {
      return;
    }

    self.map.removeLayer(item);
  }
}
