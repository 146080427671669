app.controller('BillsCtrl', [
  '$scope', '$routeParams', '$location', '$window', 'fixRailsSerializer', 'Client', 'Bill', 'Calendar', 'BillsNote',
  function($scope, $routeParams, $location, $window, fixRailsSerializer, Client, Bill, Calendar, BillsNote) {
    $scope.todays_date = Calendar.dateToString( new Date() );
    $scope.sort_options = [{name: 'State', field: 'customer.state'}, {name: 'Entry Date', field: 'entry_date'}, {name: 'Service Date', field: 'service_date'}];
    $scope.sort_field = 'customer_state'
    $scope.nc_reasons = ['Unbillable', 'No Pictures'];

    $scope.filter_enabled = false;
    $scope.service_order_id = '';
    $scope.customer = '';
    $scope.parts_box = null;
    $scope.filter_badge = 0;

    $scope.noteBody = '';

    Client.$get('/clients/' + $routeParams['client_id'] + '?company=' + $routeParams['company']).then(
      function( resource )
      {
        $scope.client = resource;
        for(i=1; i<=4; i++){
          custom_field_title = $scope.client['custom_'+ i + '_title']
          if(custom_field_title != ""){
        	  $scope.sort_options.push({name: custom_field_title, field: 'service_order.custom_'+ i +'_value'});
        	}
        }
        load_bills();
      }
    );

    $scope.bulk_select = function(){
      angular.forEach( $scope.bills, function(bill)
      {
        if (bill.tech_report_id) {
          bill.selected = $scope.bulk_selection;
        }
      });
    }

    function load_bills()
    {
      Bill.query({ client_id: $scope.client.id, company: $routeParams['company'] }).then(
        function( resources )
        {
          $scope.bills = resources;
          $scope.collection = resources;
          $scope.filter_enabled = true;
        }
      );
    }

    $scope.clean_filters = function () {
      $scope.filter_enabled = false;
      $scope.service_order_id = '';
      $scope.customer = '';
      $scope.parts_box = null;
      filter_with_delay(0);
      $scope.filter_enabled = true;
    }

    $scope.removeNote = function (bill_id, note_id) {
      let success = confirm("Are you sure?");

      if (success) {
        let billIndex = $scope.bills.findIndex(function(elem) { return elem.id == bill_id });
        let bill = $scope.bills[billIndex];
        let notes = bill.notes;
        let noteIndex = notes.findIndex(function(elem) { return elem.id == note_id });

        BillsNote.$delete("/bills/" + bill_id + "/notes/" + note_id + ".json").then(function (response) {
          notes.splice(noteIndex, 1);
          bill.notes = notes

          $scope.bills.splice(billIndex, 1, bill);
          $scope.$apply();
        })
      }
    }

    $scope.addNote = function (bill_id) {
      let body = this.noteBody;
      this.noteBody = '';

      BillsNote.$post("/bills/" + bill_id + "/notes.json", { body: body }).then(function(response) {
        let index = $scope.bills.findIndex(function(elem) { return elem.id == response.notable_id });
        let bill = $scope.bills[index];
        bill.notes.unshift(response);

        $scope.bills.splice(index, 1, bill);
        $scope.$apply();
      });
    }

    $scope.$watch('service_order_id', function () {
      if ($scope.filter_enabled) {
        filter_with_delay(1);
      } else {
        update_badge();
      }
    })

    $scope.$watch('parts_box', function () {
      if ($scope.filter_enabled) {
        filter_with_delay(0);
      } else {
        update_badge();
      }
    })

    $scope.$watch('customer', function () {
      if ($scope.filter_enabled) {
        filter_with_delay(1);
      } else {
        update_badge();
      }
    })

    filter_with_delay = function(seconds) {
      $('#api-access').show(function () {
        var delay = seconds * 1000; // Transform in milliseconds
        var delayTimeout;

        if (delayTimeout) {
          clearTimeout(delayTimeout);
        }

        if (delay > 0) {
          delayTimeout = setTimeout(filter, delay);
        } else {
          filter();
        }
      });
    }

    filter = function () {
      var list = $scope.collection;

      if ($scope.service_order_id.replace(' ', '').length > 0) {
        list = list.filter(function(elem) {
          return elem.service_order_id.toString().includes($scope.service_order_id.replace(' ', ''))
        })
      }

      if ($scope.customer.replace(' ', '').length > 0) {
        list = list.filter(function(elem) {
          return elem.customer.name.toString().toLowerCase().includes($scope.customer.toLowerCase().trim())
        })
      }

      if ($scope.parts_box !== null) {
        list = list.filter(function(elem) {
          if ($scope.parts_box === true) {
            return elem.parts_reports_items.length > 0
          } else {
            return elem.parts_reports_items.length == 0
          }
        })
      }

      $scope.bills = list;
      update_badge();
      $('#api-access').hide();
    }

    update_badge = function () {
      $scope.filter_badge = 0;

      if ($scope.service_order_id.replace(' ', '').length > 0) {
        $scope.filter_badge += 1;
      }

      if ($scope.customer.replace(' ', '').length > 0) {
        $scope.filter_badge += 1;
      }

      if ($scope.parts_box !== null) {
        $scope.filter_badge += 1;
      }

      if (!$scope.$$phase) {
        $scope.$apply();
      }
    }

    $scope.changed = function( bill, attribute, value ) {
      change = {};
      change[attribute] = value;
      // ha ha, you can't do {attribute:value}

      Bill.$put("/bills/" + bill.id, change );
    }

    $scope.finalize = function(bill) {
      let params = {
        photos_sent: bill.photos_sent,
        report_sent: bill.report_sent,
        amount: bill.amount,
        sent_at: bill.sent_at,
        nc: bill.nc,
        nc_reason: bill.nc_reason
      }

      Bill.$post('/bills/' + bill.id + '/finalize', params).then(function(response) {
        let index = $scope.bills.findIndex(function(elem) { return elem.id == bill.id });

        $scope.bills.splice(index, 1, response);
        $scope.$apply();
      })
    }

    $scope.selections = function()
    {
      var output = [];
      angular.forEach( $scope.bills, function(bill)
      {
        if( bill.selected )
        {
          output.push( bill );
        }
      });

      return output;
    }

    $scope.print_tech_reports = function( bills )
    {
      var tech_report_ids = "";
      angular.forEach(bills, function(bill)
      {
        if( tech_report_ids == "" )
        {
          tech_report_ids = bill.tech_report_id.toString();
        }
         else
        {
          tech_report_ids = tech_report_ids + "," + bill.tech_report_id.toString();
        }
      });

      $window.location.href = "/tech_reports/print?ids=" + tech_report_ids;
    }

    $scope.clean_name = function( name )
    {
      return name.replace(/ *: *$/, '')
    }

    $scope.items_total_value = function (bill) {
      if (bill.parts_reports_items.length > 0) {
        return parseFloat(bill.parts_reports_items.map(item => item.quantity * item.customer_price).reduce((prev, next) => prev + next));
      } else {
        return 0;
      }
    }

    $scope.total_value = function (bill) {
      return $scope.items_total_value(bill) + parseFloat(bill.amount || 0) + parseFloat(bill.customer.travel_fee || 0)
    }

    $scope.disable_fields = function (bill) {
      return bill.old || bill.status == 'Sent'
    }

    $scope.uniqueCauses = function(furniture_reports) {
      if (furniture_reports === null) {
        return [];
      }

      let causes = furniture_reports.map(report => report.furniture_report.cause).filter(cause => cause !== ''); // Map causes and remove empty
      let uniqueCauses = [...new Set(causes)]; // Remove duplicates and convert to array
      return uniqueCauses;
    }
  }
]);
