import React from 'react';
import Loading from '../common/Loading';
import Form from './Form';
import {Link} from 'react-router-dom'

export default class CustomerShow extends React.Component {
  constructor(props) {
    super(props);
    this.renderService = this.renderService.bind(this);
  }

  componentWillMount() {
    this.props.customerLoad(this.props.match.params.customer_id);
  }

  render()
  {
    if( !this.props.customer )
    {
      return(
        <Loading />
      );
    }
    else
    {
      return(
        <Form {...this.props} />
      )
    }
  }

  actuallyRender()
  {
    return(
      <div>
        {this.renderHeader(this.props.customer)}
        <div className="row">
          <div className="span3">
            {this.renderFacts(this.props.facts)}
          </div>
          <div className="span5">
            <h2>Service History</h2>
            {this.props.services.map(this.renderService)}
            <button className="btn btn-default" onClick={this.new_service_order(this.props.customer)}>New Service Order</button>
            {/*
            &nbsp;
            <button className="btn btn-default" onClick={this.new_preq(this.props.customer)}>New PreQualification</button>
            &nbsp;
            <button className="btn btn-default" onClick={this.new_viz_tech(this.props.customer)}>New VizTech</button>
            &nbsp;
            <button className="btn btn-default" onClick={this.new_call(this.props.customer)}>New Call</button>
            */}
          </div>
        </div>
      </div>
    )
  }

  renderFacts(facts)
  {
    return(
      facts.map( (fact) => { return(<Fact fact={fact} key={fact.name + fact.value} />)})
    );
  }

  renderService(service)
  {
    return(
      <div className="app-Service well well-small" key={service.id}>
        <div className="label">{service.status}</div>
        <h3 className="ident">
          <a href={service.path}>{service.ident}</a>
        </h3>
        <div>Client: {service.client.name}</div>
        <div>WO/episode: {service.number}</div>
        <div>Entered: {service.entered}</div>

        {this.renderFollowUp(service)}
      </div>
    );
  }

  renderFollowUp(service)
  {
    if(service.class == 'ServiceOrder')
    {
      return <button className="btn btn-success" onClick={this.followup(service)}>Create Follow-Up Service Order</button>
    }
    else
    {
      return(null);
    }
  }

  followup(service)
  {
    return( () => {document.location.href = "/ng/#/service_orders/" + service.id + "/follow-up"});
  }

  new_service_order(customer)
  {

    return( () => {document.location.href = "/ng/#/customers/" + customer.id + "/service_orders/new"});
  }

  new_preq(customer)
  {
    return( () => {document.location.href = "/ng/#/customers/" + customer.id + "/pre_qs/new"});
  }

  new_viz_tech(customer)
  {
    return( () => {document.location.href = "/ng/#/customers/" + customer.id + "/viz_techs/new"});
  }

  new_call(customer)
  {
    return( () => {document.location.href = "/calls/new?call[customer_id]=" + customer.id});
  }

  renderHeader(customer)
  {
    return(
      <div className="page-header">
        <h1><span className="muted">C{customer.id}</span> {customer.first_name} {customer.last_name}</h1>
        <Link to={'/customers/' + customer.id + '/edit'} className="btn">Edit</Link>
      </div>
    )
  }
}
